<template>
    <div class="live_add_eidt">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="500px" :append-to-body="true"
            :close-on-click-modal='false' @close="cancelBtn">
            <div slot="title">
                <div class="name">编辑模板</div>
            </div>
            <div class="main_info_live">
                <div class="info_text">
                    <div class="t_left"><span>*</span>模板名称</div>
                    <div class="t_right">
                        <el-input v-model="info.name" maxlength="10" :clearable="true" placeholder="请输入分类名称"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>分类</div>
                    <div class="t_right">
                        <el-select v-model="info.category_id" :clearable="true" placeholder="请选择分类">
                            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>是否免费</div>
                    <div class="t_right">
                        <el-select v-model="info.is_free" :clearable="true" placeholder="请选择是否免费">
                            <el-option v-for="item in freeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text" v-if="info.is_free == 2">
                    <div class="t_left"><span>*</span>价格</div>
                    <div class="t_right">
                        <el-input v-model="info.price" :clearable="true" placeholder="请输入价格(钻石)" @input="limitNum($event,'price')"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>状态</div>
                    <div class="t_right">
                        <el-switch v-model="info.is_shelf" :active-value="1" :inactive-value="2"></el-switch>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
let that
export default {
    props: {},
    data() {
        return {
            title: '',
            categoryList: [],
            dialogVisible: false,
            // 
            info: {
                id: "",
                name: '',
                category_id: '',
                is_free: '',
                price: '',
                is_shelf: '',
            },
            // 是否免费
            freeList: [
                { value: 1, label: '是' },
                { value: 2, label: '否' },
            ],
            // 
            subLoading: false
        };
    },

    computed: {},

    watch: {},

    created() {
        that = this
    },

    mounted() { },

    methods: {
        // 打开
        openDialog(info) {
            console.log(info)
            that.info = info
            that.dialogVisible = true
            this.getCat()
        },
        // 分类
        getCat() {
            that.$marketApi.liveCategories().then(res => {
                if (res.code == 1000) {
                    that.categoryList = res.result
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 取消
        cancelBtn() {
            that.info = {
                id: "",
                name: '',
                category_id: '',
                is_free: '',
                price: '',
                is_shelf: 2,
            }
            that.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            var obj = JSON.parse(JSON.stringify(that.info))
            if (obj.name == '') {
                that.$warMsg('请输入模板名称')
            } else if(!obj.category_id){
                that.$warMsg('请选择分类')
            } else if(!obj.is_free){
                that.$warMsg('请选择是否免费')
            } else if(obj.is_free==2 && !obj.price){
                that.$warMsg('请输入价格')
            } else {
                that.subLoading = true
                that.$marketApi.putLive(obj, obj.id).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('affirm')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 输入限制数字
        limitNum(e, type) {
            if (type == 'price') {
                this.info.price = e.replace(/[^0-9]/g, '')
            }
        },
    }
}
</script>
<style lang='scss' scoped>
.live_add_eidt {}

.main_info_live {
    width: 100%;

    .info_text {
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .t_left {
            // width: 80px;
            padding-right: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            text-align: right;

            span {
                color: #e51d41;
            }
        }

        .t_right {
            position: relative;
            width: 360px;

            .el-input {
                width: 100%;
            }

            .el-select {
                width: 100%;
            }

            ::v-deep .el-textarea {
                width: 100%;

                .el-textarea__inner {
                    padding-bottom: 20px;
                }
            }

            .input_btn {
                position: absolute;
                bottom: 5px;
                left: 10px;
                padding: 0px;
                background: #fff;
            }
        }
    }

    .info_text:last-child {
        padding-bottom: 0;
    }
}</style>