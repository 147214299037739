<!-- @Author: Yu_Bo -->
<template>
	<div class='empty_data'>
		<img :src="imgUrl" alt="">
    <span>{{text}}</span>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      img:{
        type: String,
        defaul: ''
      },
      text:{
        type: String,
        defaul: '暂无数据'
      }
    },
		data() {
			return{

			}
		},
		computed: {
      imgUrl(){
        if(this.img){
          return require('@/assets/images/no_html/'+this.img)
        }
      },
    },
		watch: {},
		created() {},
		mounted() {},
		methods: {},
	}
</script>

<style lang='scss' scoped>
	.empty_data{
    width: 100%;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
      display: block;
      width: 260px;
      height: 200px;
    }
    span{
      padding-top: 10px;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
	}
</style>
