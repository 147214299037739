<!-- @Author: Yu_Bo 2023-09-07 10:54:40-->
<template>
    <div class="model_vue">
        <!-- 标题 -->
        <div class="vue_title">
            <div class="nav_txt" :class="navIndex == 1 ? 'nav_active' : ''" @click="navBtn(1)">模板管理</div>
            <div class="nav_txt" :class="navIndex == 2 ? 'nav_active' : ''" @click="navBtn(2)">模板分类</div>
        </div>
        <!--  -->
        <div class="model_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-input v-model="searchInfo.name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' v-if="navIndex==1" :clearable="true" placeholder="搜索模板名称"></el-input>
                    <el-select v-model="searchInfo.category_id" size="small" @change="searchBtn" :clearable="true" placeholder="选择模板分类" v-if="navIndex==1">
                        <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="searchInfo.is_free" size="small" @change="searchBtn" :clearable="true" placeholder="是否免费" v-if="navIndex==1">
                        <el-option v-for="item in freeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="searchInfo.is_shelf" size="small" @change="searchBtn" :clearable="true" placeholder="选择状态" v-if="navIndex==1">
                        <el-option v-for="item in shelfList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addModelClassify" v-if="navIndex==2" size="small" icon="el-icon-plus">添加分类</el-button>
                    <el-input v-model="sortName" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' v-if="navIndex==2" :clearable="true" placeholder="搜索分类名称"></el-input>
                </div>
            </div>
            <!-- 图片列表 -->
            <div class="main_table" v-show="navIndex == 1">
                <live-list ref="model"></live-list>
            </div>
            <div class="main_table" v-show="navIndex == 2">
                <sort-list ref="sort"></sort-list>
            </div>
        </div>
    </div>
</template>

<script>
import LiveList from '../components/live/live_list'//模板列表
import SortList from '../components/live/sort_list'//分类列表
export default {
    components: { LiveList, SortList },
    props: {},
    data() {
        return {
            navIndex: 1,
            searchInfo:{
                name: '',
                category_id: '',
                is_free:"",
                is_shelf:"",
            },
            // 分类
            categoryList: [],
            // 是否免费
            freeList:[
                {value:1,label:'是'},
                {value:2,label:'否'},
            ],
            // 状态
            shelfList:[
                {value:1,label:'是'},
                {value:2,label:'否'},
            ],
            // 分类搜索
            sortName:'',
        };
    },

    computed: {},

    watch: {},

    created() { },

    mounted() {
        this.getCat()
    },

    methods: {
        // 参数
        getCat() {
            var that = this
            that.$marketApi.liveCategories().then(res => {
                if (res.code == 1000) {
                    that.categoryList = res.result
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 切换
        navBtn(val) {
            this.navIndex = val
            this.searchInfo={
                name: '',
                category_id: '',
                is_free:"",
                is_shelf:"",
            }
            this.sortName = ''
            this.searchBtn()
        },
        // 搜索
        searchBtn() {
            if (this.navIndex == 1) {
                this.$refs.model.search(this.searchInfo)
            } else if (this.navIndex == 2) {
                // 分类
                var obj = {
                    name: this.sortName,
                }
                this.$refs.sort.search(obj)
            }
        },
        // 添加分类
        addModelClassify(){
            this.$refs.sort.addClassify()
        },
    }
}
</script>
<style lang='scss' scoped>
.model_vue {
    width: 100%;

    .nav_txt {
        cursor: pointer;
        margin-right: 40px;
    }

    .nav_active {
        color: #2e4bf2;
    }

    .model_main {
        width: 100%;
        padding: 0 20px;

        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cond_left {
                display: flex;
                align-items: center;

                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }

                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }

                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>